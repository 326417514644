<template>
	<div class="container">
		<div class="modal" v-if="resModal"></div>
		<div class="res-modal flex-column align-center" v-if="resModal">
			<div class="res-ti">提交成功</div>
			<div class="res-con flex-row align-center justify-center">您的发票申请已提交，因受月初报税影响，开票周期可能会延长。</div>
			<div class="res-btn" @click.stop="closeModal()">确认</div>
		</div>
		<div class="invoice-wrap">
			<div class="title">申请发票</div>
			<div class="desc">
				根据国家相关法律法规，买家可向平台申请B2C商城购买发票。C2C平台属个人之间的二手物品交易，平台不负责提供发票。因所在税务机关每月对发票份额有管理要求，可能需要排队等候，敬请谅解！</div>
			<div class="con-wrap">
				<div class="invoice-info">
					<div class="invoice-ti">发票信息</div>
					<div class="flex-row align-center form-item">
						<div class="for-ti">开票人姓名:</div>
						<input v-model="userName" type="text" placeholder="必须与账户实名一致">
					</div>
					<div class="flex-row align-center form-item">
						<div class="for-ti">身份证:</div>
						<input v-model="idNo" type="text" placeholder="必须与账户实名一致">
					</div>
					<div class="flex-row align-center form-item">
						<div class="for-ti">手机号:</div>
						<input v-model="phone" type="text" placeholder="注册手机号">
					</div>
					<div class="flex-row align-center form-item">
						<div class="for-ti">邮箱:</div>
						<input v-model="email" type="text" placeholder="用于接收电子发票">
					</div>

				</div>
				<div class="order-info" v-if="pageLoad">
					<div class="order-ti">订单信息</div>
					<div v-for="(item,index) in list" :key="index" class="order-item flex-row align-center">
						<div class="flex-row align-center">
							<div class="form-ti">订单号:</div>
							<input type="text" v-model="order[index].no" class="form-input" placeholder="请输入订单号">
						</div>
						<div class="flex-row align-center">
							<div class="form-ti">金额:</div>
							<input type="number" v-model="order[index].price" class="form-input-price"
								placeholder="请输入金额">
						</div>
					</div>
				</div>
			</div>
			<div class="submit" @click="submit()">提交</div>
		</div>
	</div>
</template>

<script>
	import config from '../config/config.js';
	import {
		Toast,
	} from 'vant';
	import 'vant/lib/toast/index.css'
	export default {
		name: 'Home',
		data() {
			return {
				userName: '',
				idNo: '',
				phone: '',
				email: '',
				orderNo: '',
				address: '',
				list: 10,
				order: [{
					no: '',
					price: ''
				}],
				pageLoad: 0,
				isSubmit: 0,
				resModal:0
			}
		},
		mounted() {
			console.log('mounted')
			let order = [];
			for (let i = 0; i < 10; i++) {
				order.push({
					no: '',
					price: ''
				})
			}
			this.order = order;
			this.pageLoad = 1;
		},
		methods: {
			closeModal(){
				this.resModal=0
			},
			submit() {
				const that = this;
				
				if (that.isSubmit) return;
				if (!that.userName) {
					Toast('请输入开票人姓名');
					return;
				}
				if (!that.idNo) {
					Toast('请输入身份证号');
					return;
				}
				if (!that.phone) {
					Toast('请输入手机号');
					return;
				}
				if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.phone)) {
					Toast('请输入手机号')
					return;
				}
				if (!that.email) {
					Toast('请输入邮箱');
					return;
				}
				if (!/^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/.test(that.email)) {
					Toast('请输入邮箱');
					return;
				}
				let orderStr = '';
				let orderArr = [];
				for (let i in that.order) {
					if (that.order[i].no != '' && that.order[i].price != '') {
						orderArr.push(that.order[i].no)
					} else if (that.order[i].no != '' && that.order[i].price <= 0) {
						Toast('请输入订单金额')
						return;
					} else if (that.order[i].no == '' && that.order[i].price > 0) {
						Toast('请输入订单号')
						return;
					}
				}
				if (orderArr.length <= 0) {
					Toast('请输入订单信息')
					return;
				}
				
				if(!that.$store.state.user.token){
					this.$router.push('/login');
					return;
				}
				orderStr = orderArr.join(',');
				let params = {
					userName: that.userName,
					idNo: that.idNo,
					phone: that.phone,
					email: that.email,
					orderNo: orderStr,
					address: that.address,
				}
				that.isSubmit = 1;
				Toast.loading({
					message: '提交中...',
					forbidClick: true,
					duration: 0,
				});
				this.axios.post(config.pointsRequest + '/nft-api/rest/invoiceApply/applyInvoice', params, {
					headers: {
						'Content-Type': 'application/json',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						that.resModal=1;
						let order = [];
						for (let i = 0; i < 10; i++) {
							order.push({
								no: '',
								price: ''
							})
						}
						that.order = order;
						that.userName = '';
						that.idNo = '';
						that.phone = '';
						that.email = '';
						that.orderNo = '';
						that.address = '';
						that.isSubmit = 0
					} else {
						that.isSubmit = 0
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/login')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					that.isSubmit = 0
					Toast('提交失败，请稍后重试');
				})
			}
		}

	}
</script>
<style scoped="scoped">
	input {
		border: none;
		outline: none;
		background: initial;
	}

	input:focus {
		border: none;
		outline: none;
	}

	input:active {
		border: none;
		outline: none;
	}

	.container {
		width: 1920px;
		min-height: 100%;
		background: #F6F7FB;
		overflow: hidden;
		padding-bottom: 50px;
		margin: auto;
	}

	.invoice-wrap {
		width: 1173px;
		margin: 64px auto 0;
		padding-bottom: 10px;
		background: #FFFFFF;
		border-radius: 26px;
	}

	.title {
		width: 1173px;
		height: 143px;
		border-bottom: 1px solid #E5DDFF;
		text-align: center;
		line-height: 144px;
		font-size: 29px;
		font-weight: 600;
		color: #000000;
	}

	.desc {
		width: 1063px;
		padding: 25px 50px;
		margin: 68px auto 27px;
		box-sizing: border-box;
		background: #F9F7FF;
		border-radius: 5px;
		font-size: 21px;
		font-weight: 400;
		color: #24206A;
		line-height: 35px;
	}

	.con-wrap {
		width: 1063px;
		margin: auto;
		background: #FFFFFF;
		border-radius: 5px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		padding: 40px 60px;
		box-sizing: border-box;
	}

	.invoice-info {
		width: 100%;
	}

	.invoice-ti {
		width: 100%;
		margin-bottom: 53px;
		font-size: 27px;
		font-weight: 400;
		color: #000000;
		text-align: center;
	}

	.form-item {
		width: 100%;
		height: 60px;
		padding: 0 33px;
		margin-bottom: 20px;
		box-sizing: border-box;
		background: #F6F7FB;
		border-radius: 5px;
	}

	.for-ti {
		width: 150px;
		font-size: 21px;
		font-weight: 500;
		color: #000000;
	}

	.form-item input {
		width: 500px;
		font-size: 21px;
		background: initial;
	}

	.order-info {
		width: 100%;
		margin-top: 53px;
	}

	.order-ti {
		width: 100%;
		margin-bottom: 53px;
		font-size: 27px;
		font-weight: 400;
		color: #000000;
		text-align: center;
	}

	.order-item {
		width: 100%;
		height: 60px;
		padding: 0 33px;
		margin-bottom: 20px;
		box-sizing: border-box;
		background: #F6F7FB;
		border-radius: 5px;
	}

	.form-ti {
		font-size: 21px;
		font-weight: 400;
		color: #000000;
		margin-right: 15px;
	}

	.form-input {
		width: 400px;
		font-size: 21px;
	}

	.form-input-price {
		width: 200px;
		font-size: 21px;
	}

	.submit {
		width: 160px;
		height: 53px;
		margin: 40px auto;
		background: #5850EC;
		border-radius: 27px;
		font-size: 21px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 53px;
	}

	.modal {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		right: 0;
		z-index: 10;
		background: rgba(0, 0, 0, 0.6);
	}

	.res-modal {
		width: 695px;
		height: max-content;
		padding-bottom: 30px;
		background: #FFFFFF;
		border-radius: 13px;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		z-index: 20;
	}

	.res-ti {
		width: 695px;
		height: 67px;
		background: #FFFFFF;
		box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.22);
		border-radius: 13px 13px 0px 0px;
		text-align: center;
		line-height: 68px;
		font-size: 27px;
		font-weight: 600;
		color: #000000;
	}

	.res-con {
		width: 100%;
		height: 119px;
		text-align: center;
		font-size: 21px;
		font-weight: 400;
		color: #000000;
	}

	.res-btn {
		width: 160px;
		height: 53px;
		background: #5850EC;
		border-radius: 27px;
		font-size: 21px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 53px;
	}
</style>
